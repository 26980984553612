import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import SendIcon from '@material-ui/icons/Send';

import styles from '../../assets/jss/material-dashboard-react/components/contactStyle';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(styles);

const InitialFValues = {
  nameField: '',
  emailField: '',
  messageField: '',
};

export default function Contact() {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [values, setValues] = useState(InitialFValues);

  const validateEmail = () => {
    let temp = {};

    temp.emailField = RegExp(/\S+@\S+\.\S+/).test(values.emailField)
      ? ''
      : 'Courriel non valide.';
    setError(temp.emailField);

    return error;
  };

  // const PostData = () => {};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log('name: ', name, 'value: ', value);
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    console.log('Handling Submit');
    e.preventDefault();
    if (validateEmail()) {
      console.log('In PostData');
      fetch('/send', {
        method: 'post',
        header: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: values.nameField,
          email: values.emailField,
          subject: 'Envoie du site Signalisation',
          message: values.messageField,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.message);
          values.nameField = '';
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.cardContainer}>
        <GridContainer>
          <GridItem xs={8} sm={8} md={8}>
            <Card>
              <CardHeader color="blue">
                <h4 className={classes.cardTitleWhite}>Nous joindre</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        required: true,
                      }}
                      inputProps={{
                        onChange: (e) => handleInputChange(e),
                        name: 'nameField',
                        autoFocus: true,
                      }}
                      id="name"
                      labelText="Nom"
                      value={values.nameField}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        required: true,
                      }}
                      id="email"
                      inputProps={{
                        onChange: (e) => handleInputChange(e),
                        onBlur: (e) => validateEmail(e),
                        name: 'emailField',
                      }}
                      labelText="Courriel"
                      error={error !== ''}
                      value={values.emailField}
                    />
                    {error === '' ? null : (
                      <FormHelperText error>{error}</FormHelperText>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        required: true,
                      }}
                      id="message"
                      inputProps={{
                        name: 'messageField',
                        multiline: true,
                        onChange: (e) => handleInputChange(e),
                        rows: 5,
                      }}
                      labelText="Commentaire"
                      value={values.messageField}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.cardFooter}>
                  <CardFooter>
                    <Button
                      color="blue"
                      disableRipple
                      fullWidth
                      href={`mailto:signalisation@ville.terrebonne.qc.ca
                      ?subject=Envoie%20du%20site%20Signalisation
                      &body=${values.messageField}`}
                      // type="submit"
                      className={classes.buttonLabel}
                      endIcon={<SendIcon />}
                    >
                      Envoyé
                    </Button>
                  </CardFooter>
                </div>
              </GridItem>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </form>
  );
}
