const contactStyle = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  cardContainer: {
    margin: 'auto',
  },
  cardFooter: {
    paddingBottom: '1rem',
  },
  buttonLabel: {
    textTransform: 'none',
  },
};

export default contactStyle;
