import React, { useState } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import Search from '@material-ui/icons/Search';
import EmailIcon from '@material-ui/icons/Email';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

// core components
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-react/components/headerLinksStyle.js';

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();

  const [input, setInput] = useState('');

  return (
    <div>
      <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: classes.margin + ' ' + classes.search,
          }}
          inputProps={{
            placeholder: 'Rechercher...',
            inputProps: {
              'aria-label': 'Search',
              onChange: (e) => setInput(e.target.value),
              onKeyDown: (e) => {
                if (e.keyCode === 13) {
                  window.location.href = `/admin/Search/searchQuery=${input}`;
                }
              },
            },
          }}
        />
        <Button
          color="white"
          aria-label="loupe"
          justIcon
          round
          disableRipple
          href={`/admin/Search/searchQuery=${input}`}
        >
          <Search />
        </Button>
      </div>
      <Button
        color="white"
        className={classes.Button}
        startIcon={<EmailIcon />}
        simple
        href="/admin/Contact"
      >
        Nous joindre
      </Button>
      <Button
        color="white"
        className={classes.Button}
        startIcon={<ContactSupportIcon />}
        simple
        href="/admin/Apropos"
      >
        À propos
      </Button>
    </div>
  );
}
