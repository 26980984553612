import DashboardPage from 'views/Dashboard/Dashboard.js';

const dashboardRoutes = [
  {
    path: '/categorie/:category',
    name: 'Category',
    component: DashboardPage,
    layout: '/admin',
    key: 'Category',
  },
];

export default dashboardRoutes;
