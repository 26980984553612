import React, { createRef, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Navbar from '../components/Navbars/Navbar.js';
import Footer from '../components/Footer/Footer.js';
import Sidebar from '../components/Sidebar/Sidebar.js';

import logo from 'assets/img/TB-ver-inv-web125.png';
import routes from 'routes.js';
import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js';
import Apropos from 'views/Apropos/Apropos';
import Search from 'views/Search/Search';
import Contact from 'views/Forms/Contact';

import api from '../services/panneau';

let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    })}
    <Route path="/admin/Apropos" component={Apropos} />
    <Route path="/admin/Contact" component={Contact} />
    <Route path="/admin/Search/:searchQuery" component={Search} />
    <Redirect from="/admin" to="/admin/categorie/Danger" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin(props, { ...rest }) {
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef(null);
  // states and functions
  const [mobileOpen, setMobileOpen] = useState(false);
  const [categories, setCategories] = useState('');
  const [dbUpdate, setDBupdate] = useState('');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  useEffect(() => {
    api
      .fetchSettings()
      .then((response) => {
        setCategories(response.data.categories);
        setDBupdate(response.data.dbUpdate);
      })
      .catch((err) => console.log(err));
  }, []);

  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    console.log(
      navigator.userAgent.indexOf('Win') > -1,
      navigator.userAgent.indexOf('Win')
    );
    if (navigator.userAgent.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    window.addEventListener('resize', resizeFunction);

    if (mainPanel.current) mainPanel.current.scrollTop = 0;
    // Specify how to clean up after this effect:
    return function cleanup() {
      // if (navigator.userAgent.indexOf('Win') > -1) {
      //   // ps.destroy();
      // }
      window.removeEventListener('resize', resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        categories={categories}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          // routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          titleText="Répertoire des panneaux de signalisation"
          dbupdate={dbUpdate}
          {...rest}
          id="mainBody"
        />
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
