import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// @material-ui/core
import {
  Breadcrumbs,
  LinearProgress,
  TablePagination,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// core components
import CardDetail from 'components/Card/CardDetail';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// api call
import api from '../../services/panneau';

// css
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

const breadcrumbNameMap = {
  Danger: 'Danger',
  Indication: 'Indication',
  Destination: 'Indication › Destination',
  Services: 'Indication › Équipements touristiques › Services',
};

const useStyles = makeStyles(styles);

export default function Search() {
  const classes = useStyles();
  const { searchQuery } = useParams();
  const currentQuery = searchQuery.split('=')[1];
  const [signData, setSignData] = useState({
    totalPages: 0,
    page: 0,
    items: [],
    totalItems: 0,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [categorie, setCategorie] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (categorie) => {
    setCategorie(categorie);
  };

  useEffect(() => {
    setLoading(true);
    api
      .fetchWithSearch(
        searchQuery.split('=')[1] + `&Page=${page}&PageSize=${rowsPerPage}`
      )
      .then((response) => {
        setSignData(response.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [page, rowsPerPage, searchQuery]);

  return (
    <div>
      {signData.items.length && page >= 0 ? (
        <div>
          <Breadcrumbs separator="›">
            <Typography>
              {categorie ? breadcrumbNameMap[categorie] : null}
            </Typography>
          </Breadcrumbs>
          <GridContainer>
            <GridItem xs={12}>
              <Typography className={classes.search} paragraph variant="h6">
                Résultat de recherche {currentQuery}
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <TablePagination
                className={classes.toolbarWrapper}
                component="div"
                count={signData.totalItems}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
                labelRowsPerPage={'Éléments'}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[12, 24, 48]}
              />
            </GridItem>
          </GridContainer>
        </div>
      ) : null}
      <GridContainer>
        {signData.items.length
          ? signData.items.map((c) => {
              return (
                <CardDetail data={c} key={c.id} handleClick={handleClick} />
              );
            })
          : null}
      </GridContainer>
      {signData.items.length > 20 && page >= 0 ? (
        <div></div>
      ) : loading ? (
        <div style={{ width: '100%' }}>
          <LinearProgress />
        </div>
      ) : signData.items.length <= 0 ? (
        <div>
          <Typography>Aucun panneau à afficher</Typography>
        </div>
      ) : null}
    </div>
  );
}
