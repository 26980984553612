import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
} from 'assets/jss/material-dashboard-react.js';

const sidebarStyle = (theme) => ({
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background: blackColor,
      opacity: '.8',
    },
  },
  divider: {
    marginTop: '10px',
    backgroundColor: '#fff',
  },
  drawerPaper: {
    backgroundColor: 'hsla(222, 37%, 19%, 0)',
    color: '#fff',
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1',
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: '0',
      left: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
  },
  logo: {
    display: 'block',
    height: '65px',
    position: 'relative',
    justifyContent: 'center',
    padding: '15px 15px',
    zIndex: '4',
    marginBottom: '25px',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-25px',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'rgba(' + hexToRgb(grayColor[6]) + ', 0.3)',
    },
  },
  logoLink: {
    ...defaultFont,
    textTransform: 'uppercase',
    padding: '5px 10px',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: whiteColor,
    },
  },
  logoTitle: {
    ...defaultFont,
    textTransform: 'uppercase',
    padding: '5px 10px',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: whiteColor,
    },
  },
  logoImage: {
    width: 'auto',
    display: 'inline-block',
    height: 'auto',
  },
  img: {
    width: 'auto',
    height: '70px',
    top: '22px',
    position: 'absolute',
    marginLeft: '39px',
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    '&:hover,&:focus,&:visited,&': {
      color: whiteColor,
    },
  },
  itemLink: {
    width: 'auto',
    transition: 'all 300ms linear',
    margin: '10px 15px 0 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px 15px 0 0',
    backgroundColor: 'transparent',
    ...defaultFont,
    '&:hover,&:focus': {
      backgroundColor: 'rgb(139,180,212)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgb(23,105,170)',
      '&:hover,&:focus': {
        backgroundColor: 'rgb(23,105,170)',
      },
    },
  },
  itemLinkMargin: {
    width: 'auto',
    transition: 'all 300ms linear',
    margin: '10px 15px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px 15px 0 24px',
    backgroundColor: 'transparent',
    ...defaultFont,
    '&:hover,&:focus': {
      backgroundColor: 'rgb(139,180,212)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgb(23,105,170)',
      '&:hover,&:focus': {
        backgroundColor: 'rgb(23,105,170)',
      },
    },
  },
  itemIcon: {
    width: '24px',
    height: '30px',
    fontSize: '24px',
    lineHeight: '30px',
    float: 'left',
    marginRight: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'rgba(' + hexToRgb(whiteColor) + ', 0.8)',
  },
  itemText: {
    fontFamily: 'Verdana',
    margin: '0',
    lineHeight: '30px',
    fontSize: '13px',
    color: whiteColor,
  },
  list: {
    marginTop: '20px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '50px',
    marginBottom: '0',
    listStyle: 'none',
    position: 'unset',
  },
  nested: {
    paddingLeft: theme.spacing(3),
    fontSize: '1rem',
  },
  whiteFont: {
    color: whiteColor,
  },
  purple: {
    backgroundColor: primaryColor[0],
    ...primaryBoxShadow,
    '&:hover,&:focus': {
      backgroundColor: primaryColor[0],
      ...primaryBoxShadow,
    },
  },
  blue: {
    backgroundColor: infoColor[0],
    boxShadow:
      '0 12px 20px -10px rgba(' +
      hexToRgb(infoColor[0]) +
      ',.28), 0 4px 20px 0 rgba(' +
      hexToRgb(blackColor) +
      ',.12), 0 7px 8px -5px rgba(' +
      hexToRgb(infoColor[0]) +
      ',.2)',
    '&:hover,&:focus': {
      backgroundColor: infoColor[0],
      boxShadow:
        '0 12px 20px -10px rgba(' +
        hexToRgb(infoColor[0]) +
        ',.28), 0 4px 20px 0 rgba(' +
        hexToRgb(blackColor) +
        ',.12), 0 7px 8px -5px rgba(' +
        hexToRgb(infoColor[0]) +
        ',.2)',
    },
  },
  green: {
    backgroundColor: successColor[0],
    boxShadow:
      '0 12px 20px -10px rgba(' +
      hexToRgb(successColor[0]) +
      ',.28), 0 4px 20px 0 rgba(' +
      hexToRgb(blackColor) +
      ',.12), 0 7px 8px -5px rgba(' +
      hexToRgb(successColor[0]) +
      ',.2)',
    '&:hover,&:focus': {
      backgroundColor: successColor[0],
      boxShadow:
        '0 12px 20px -10px rgba(' +
        hexToRgb(successColor[0]) +
        ',.28), 0 4px 20px 0 rgba(' +
        hexToRgb(blackColor) +
        ',.12), 0 7px 8px -5px rgba(' +
        hexToRgb(successColor[0]) +
        ',.2)',
    },
  },
  orange: {
    backgroundColor: warningColor[0],
    boxShadow:
      '0 12px 20px -10px rgba(' +
      hexToRgb(warningColor[0]) +
      ',.28), 0 4px 20px 0 rgba(' +
      hexToRgb(blackColor) +
      ',.12), 0 7px 8px -5px rgba(' +
      hexToRgb(warningColor[0]) +
      ',.2)',
    '&:hover,&:focus': {
      backgroundColor: warningColor[0],
      boxShadow:
        '0 12px 20px -10px rgba(' +
        hexToRgb(warningColor[0]) +
        ',.28), 0 4px 20px 0 rgba(' +
        hexToRgb(blackColor) +
        ',.12), 0 7px 8px -5px rgba(' +
        hexToRgb(warningColor[0]) +
        ',.2)',
    },
  },
  red: {
    backgroundColor: dangerColor[0],
    boxShadow:
      '0 12px 20px -10px rgba(' +
      hexToRgb(dangerColor[0]) +
      ',.28), 0 4px 20px 0 rgba(' +
      hexToRgb(blackColor) +
      ',.12), 0 7px 8px -5px rgba(' +
      hexToRgb(dangerColor[0]) +
      ',.2)',
    '&:hover,&:focus': {
      backgroundColor: dangerColor[0],
      boxShadow:
        '0 12px 20px -10px rgba(' +
        hexToRgb(dangerColor[0]) +
        ',.28), 0 4px 20px 0 rgba(' +
        hexToRgb(blackColor) +
        ',.12), 0 7px 8px -5px rgba(' +
        hexToRgb(dangerColor[0]) +
        ',.2)',
    },
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'auto',
    width: '260px',
    zIndex: '4',
    overflowScrolling: 'touch',
  },
  activePro: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '100%',
      bottom: '13px',
    },
  },
});

export default sidebarStyle;
