import axios from 'axios';

let BASE_URL = 'https://apisignalisation.ville.terrebonne.qc.ca/'; // Production

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  BASE_URL = 'https://localhost:5001/'; //Utiliser lors du développement
}

const instance = axios.create({
  baseURL: BASE_URL,
});

export default {
  fetchByCategory: (category) =>
    instance({
      method: 'GET',
      url: `Panneau/${category}`,
    }),
  fetchWithSearch: (searchQuery) =>
    instance({
      method: 'GET',
      url: `Panneau/search?searchQuery=${searchQuery}`,
    }),
  fetchSettings: () =>
    instance({
      method: 'GET',
      url: 'PanneauSettings/',
    }),
};
