import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import AdminNavbarLinks from './AdminNavbarLinks.js';

import styles from 'assets/jss/material-dashboard-react/components/headerStyle.js';

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();

  const { color, titleText, dbupdate } = props;
  const appBarClasses = classNames({
    [' ' + classes[color]]: color,
  });

  return (
    <>
      <div className={classes.backgroundImg}></div>
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            <h1 className={classes.title}>{titleText}</h1>
          </div>
          <Hidden mdDown implementation="css">
            <AdminNavbarLinks />
          </Hidden>
          <Hidden lgUp implementation="css">
            <IconButton
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
              style={{ color: 'white' }}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <div className={classes.dbupdate}>Mise à jour de la BD: {dbupdate}</div>
      </AppBar>
    </>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
