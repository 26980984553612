import React from 'react';
// @material-ui/core components
import { makeStyles, Typography } from '@material-ui/core';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

const styles = (theme) => ({
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  headerTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
});

const useStyles = makeStyles(styles);

export default function Apropos() {
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem>
        <Card>
          <CardHeader color="blue">
            <h4 className={classes.cardTitleWhite}>À propos</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem>
                <Typography
                  paragraph
                  variant="body1"
                  className={classes.headerTitle}
                >
                  Le répertoire de signalisation de la Ville de Terrebonne a été
                  réalisé par la Direction des technologies de la Ville de
                  Terrebonne en collaboration avec les directions du Génie et
                  des Travaux publics, ainsi que la participation des firmes
                  Solutions Transcad et Groupe Trifide.
                </Typography>
                <Typography
                  paragraph
                  variant="body1"
                  className={classes.headerTitle}
                >
                  Le répertoire de signalisation s'inscrit dans le cadre du
                  projet d’acquisition de données géospatiales dans l’emprise de
                  rue figurant au Programme triennal d’immobilisation 2021-2023
                  (fiche 10151), projet qui vise la collecte des données
                  d'inventaire pour de nombreux actifs de la Ville de Terrebonne
                  incluant la signalisation routière. Cet outil facilite la
                  recherche et l'identification des biens de signalisation en
                  présentant des images et des fiches détaillées des panneaux de
                  signalisation routière de la Ville de Terrebonne. Les panneaux
                  reconnus et non reconnus par le ministère de Transport du
                  Québec (MTQ) font partie du répertoire et la mise à jour de
                  l'information est réalisée de façon régulière. L'information
                  présentée dans le répertoire est fournie à titre indicatif au
                  meilleur des connaissances de la Ville. Pour tout commentaire,
                  question ou suggestion d'amélioration du répertoire, veuillez
                  communiquer à l'adresse suivante :
                  geomatique@ville.terrebonne.qc.ca
                </Typography>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
