// react
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// @material-ui/core
import {
  Collapse,
  Divider,
  Drawer,
  Hidden,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
// @material-ui/icons
import { Add, Remove } from '@material-ui/icons';
// @material-ui/styles
import { makeStyles } from '@material-ui/core/styles';
// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.js';
// css
import styles from 'assets/jss/material-dashboard-react/components/sidebarStyle.js';

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();

  const { color, logo, image, categories } = props;
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('Danger');

  const handleClick = (menu) => {
    setOpen((prevState) => ({ ...prevState, [menu]: !prevState[menu] }));
  };

  function isActiveRoute({ name: routeName }) {
    return window.location.href.indexOf(routeName) > -1;
  }

  const createSubMenu = (items, baseUrl) => {
    return items.map((item) => {
      var listItemClasses;

      listItemClasses = classNames({
        [' ' + classes[color]]: isActiveRoute(props, item),
      });

      const whiteFontClasses = classNames({
        [' ' + classes.whiteFont]: isActiveRoute(props, item),
      });

      return (
        <React.Fragment key={item.name}>
          {(!item.children || item.children.length === 0) &&
          categories.length ? (
            <ListItem
              button
              className={classes.itemLinkMargin + listItemClasses}
              component={Link}
              disableRipple
              key={item.name}
              onClick={() => setSelectedIndex(item.name)}
              selected={selectedIndex === item.name}
              to={`${baseUrl}/${item.name}`}
            >
              <ListItemText
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography
                primary={item.name}
              />
            </ListItem>
          ) : (
            <>
              {item.name === 'Liens rapides' ? (
                <Divider variant="middle" classes={{ root: classes.divider }} />
              ) : null}
              <ListItem
                button
                className={classes.itemLink + listItemClasses}
                component={Link}
                disableRipple
                key={item.name}
                selected={selectedIndex === item.name}
                to={`${baseUrl}/${item.name}`}
                onClick={() => {
                  handleClick(item.name);
                  setSelectedIndex(item.name);
                }}
              >
                {open[item.name] ? (
                  <Remove className={classNames(classes.itemIcon)} />
                ) : (
                  <Add className={classNames(classes.itemIcon)} />
                )}
                <ListItemText
                  className={classNames(classes.itemText, whiteFontClasses)}
                  disableTypography={true}
                  primary={item.name}
                />
              </ListItem>
              <Collapse in={open[item.name]} timeout="auto" unmountOnExit>
                <List
                  className={classNames(classes.nested)}
                  component="nav"
                  disablePadding
                  key={item.name}
                >
                  {createSubMenu(item.children, baseUrl)}
                </List>
              </Collapse>
            </>
          )}
        </React.Fragment>
      );
    });
  };

  var brand = (
    <>
      <div className={classes.logo}>
        <a
          href="https://www.ville.terrebonne.qc.ca"
          className={classes.logoLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} alt="logo" className={classes.img} />
        </a>
      </div>
    </>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {categories.length ? (
              <>
                <AdminNavbarLinks />
                <List className={classes.list}>
                  {createSubMenu(categories, '/admin/categorie')}
                </List>{' '}
              </>
            ) : (
              <div style={{ width: '100%' }}>
                <LinearProgress />
              </div>
            )}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : (
            <div
              className={classes.background}
              style={{ backgroundColor: '#000' }}
            />
          )}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <List component="nav" className={classes.list}>
              {categories.length ? (
                createSubMenu(categories, '/admin/categorie')
              ) : (
                <div style={{ width: '100%' }}>
                  <LinearProgress />
                </div>
              )}
            </List>
          </div>
          <div className={classes.background} />
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool.isRequired,
};
