import React, { useEffect, useState } from 'react';

// @material-ui/core
import {
  Breadcrumbs,
  LinearProgress,
  makeStyles,
  TablePagination,
  Typography,
} from '@material-ui/core';

// core components
import CardDetail from 'components/Card/CardDetail';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// api call
import api from '../../services/panneau';

// css
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

const breadcrumbNameMap = {
  Danger: 'Danger',
  Indication: 'Indication',
  Destination: 'Indication › Destination',
  Services: 'Indication › Équipements touristiques › Services',
};

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();
  const { category } = props.match.params;
  const [signData, setSignData] = useState({
    totalPages: 0,
    page: 0,
    items: [],
    totalItems: 0,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (categorie) => {};

  useEffect(() => {
    setLoading(true);
    api
      .fetchByCategory(category + '?Page=' + page + '&PageSize=' + rowsPerPage)
      .then((response) => {
        setSignData(response.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [page, rowsPerPage, category]);

  return (
    <div>
      <Breadcrumbs separator="›">
        <Typography>{breadcrumbNameMap[category]}</Typography>
      </Breadcrumbs>
      {signData.items.length && page >= 0 ? (
        <GridContainer justify="flex-end" style={{ padding: 0 }}>
          <GridItem style={{ padding: 0 }}>
            <TablePagination
              className={classes.toolbarWrapper}
              component="div"
              count={signData.totalItems}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
              labelRowsPerPage={'Éléments'}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[12, 24, 48]}
            />
          </GridItem>
        </GridContainer>
      ) : null}
      <GridContainer>
        {signData.items.length
          ? signData.items.map((c) => {
              return (
                <CardDetail data={c} key={c.id} handleClick={handleClick} />
              );
            })
          : null}
      </GridContainer>
      {/* <div
        style={{
          position: 'fixed',
          bottom: 0,
          paddingTop: '10px',
          background: 'yellowgreen',
          width: '10%',
          height: '50px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          boxShadow: '0px -4px 3px rgb(27 27 24 / 75%)',
          zIndex: 1400,
        }}
      >
        TEST EN BAS
      </div> */}
      {signData.items.length > 20 && page >= 0 ? (
        <div></div>
      ) : loading ? (
        <div style={{ width: '100%' }}>
          <LinearProgress />
        </div>
      ) : signData.items.length <= 0 ? (
        <div>
          <Typography>Aucun panneau à afficher</Typography>
        </div>
      ) : null}
    </div>
  );
}
