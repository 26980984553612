import React, { useState } from 'react';

// @material-ui/core
import {
  CardActionArea,
  CardContent,
  Collapse,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// core components
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import GridItem from 'components/Grid/GridItem.js';

// css
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

export default function CardDetail(props) {
  const classes = useStyles();
  const { data, handleClick } = props;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (card) => {
    setExpanded((prevState) => ({
      ...prevState,
      [card.id]: !prevState[card.id],
    }));
    handleClick(card.categorie);
  };

  console.log(data);
  return (
    <>
      <GridItem xs={12} sm={12} md={6} lg={4} xl={3} key={data.id}>
        <Card>
          <CardActionArea disableRipple onClick={() => handleExpandClick(data)}>
            <CardHeader stats icon>
              <CardIcon color="white">
                <img
                  src={data.imageUrl}
                  className={classes.cardIcon__img}
                  alt=""
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src =
                      'https://storageaccountbotti8401.blob.core.windows.net/images/Default.png';
                  }}
                />
              </CardIcon>
              <h2 className={classes.cardTitle}>{data.code}</h2>
              <h3 className={classes.cardCategory}>{data.nom}</h3>
              <h2 className={classes.cardCategory}>{data.categorie}</h2>
              <h2 className={classes.cardCategory}>{data.source}</h2>
            </CardHeader>
          </CardActionArea>
          <h5 className={classes.cardHeaderFooter}>{data.image}</h5>
          <Collapse in={expanded[data.id]} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph variant="h6">
                Numéro :
              </Typography>
              <Typography variant="body2" component="p" gutterBottom>
                {data.code}
              </Typography>
              <Divider variant="middle" className={classes.divider} />
              <Typography paragraph variant="h6">
                Catégorie :
              </Typography>
              <Typography variant="body2" component="p" gutterBottom>
                {data.categoriePath.replaceAll('-', ' > ')}
              </Typography>
              <Divider variant="middle" className={classes.divider} />
              <Typography paragraph variant="h6">
                Description :
              </Typography>
              <Typography variant="body2" component="p" gutterBottom>
                {data.description}
              </Typography>
              <Divider variant="middle" className={classes.divider} />
              <Typography paragraph variant="h6">
                Usage(s) :
              </Typography>
              {data.usages.map((u, index) => {
                return (
                  <Typography
                    variant="body2"
                    component="p"
                    gutterBottom
                    key={index}
                  >
                    {u}
                  </Typography>
                );
              })}

              <Divider variant="middle" className={classes.divider} />
              <Typography paragraph variant="h6">
                Couleur :
              </Typography>
              <Typography variant="body2" component="p" gutterBottom>
                {data.couleur}
              </Typography>
              <Divider variant="middle" className={classes.divider} />
              <Typography paragraph variant="h6">
                Reference :
              </Typography>
              <Typography variant="body2" component="p" gutterBottom>
                {data.reference}
              </Typography>
              <Divider variant="middle" className={classes.divider} />
              <Typography paragraph variant="h6">
                Dimensions :
              </Typography>
              <TableContainer component={Paper} className={classes.table}>
                <Table>
                  <TableBody>
                    {data.dimensions.map((item) => {
                      return (
                        <TableRow key={item} className={classes.tableRow}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            padding="default"
                            className={classes.tableCell}
                          >
                            {item}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Collapse>
        </Card>
      </GridItem>
    </>
  );
}
